import React, {useEffect, useState, useContext, useCallback} from 'react';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {DealLinkedDealsResponse, LinkedDealListItem, TableColumn} from '../../../types';
import {api, getCached} from '../../../utils/Request';
import getClaimFrequency from '../../../utils/ClaimFrequencyUtils';
import {formatDate} from '../../../utils/DateUtils';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {SimpleDataTable} from '../../../components/SimpleDataTable';

type LinkedDealListItemWithId = LinkedDealListItem & {id: string};

const getGSTType = (claimAmount: number, claimTaxAmount: number) => {
  if (claimAmount !== 0 && claimTaxAmount === 0) {
    return ' free';
  } else if (claimTaxAmount !== 0) {
    return ' inc.';
  } else {
    return ' ex.';
  }
};

const DealLinkedDealsTabContent = () => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const {id = ''} = useParams<{id: string}>();
  const [rows, setRows] = useState<LinkedDealListItemWithId[]>([]);

  const fetchLinkedDeals = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<DealLinkedDealsResponse> = await getCached(
        api(Consts.Api.DealAgreementLinkedDeals.replace(':id', id))
      );
      // mapping dealId to generic id to apply styles in SimpleDataTable
      const data = response.data.data.map((d) => ({...d, id: d.dealId.toString()}));
      setRows(data);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [id, hideLoading, showLoading, setRows]);

  useEffect(() => {
    fetchLinkedDeals();
  }, [fetchLinkedDeals]);

  const columns: TableColumn<LinkedDealListItem>[] = [
    {
      id: 'description',
      label: 'Deal Description',
      minWidth: 150,
      render: (rowData) => (
        <Typography sx={{wordBreak: 'break-word'}}>{rowData.description}</Typography>
      ),
    },
    {
      id: 'createdAt',
      label: 'Created Date',
      minWidth: 80,
      render: (rowData) => formatDate(rowData.createdAt),
    },
    {
      id: 'dealId',
      label: 'Deal ID',
      minWidth: 80,
      render: (rowData) => <Typography>{rowData.dealId}</Typography>,
    },
    {
      id: 'invoiceNumber',
      label: 'Invoice Number',
      minWidth: 80,
      render: (rowData) => <Typography>{rowData.invoiceNumber}</Typography>,
    },
    {
      id: 'dealType',
      label: 'Deal Type',
      minWidth: 80,
      render: (rowData) =>
        Consts.DealTypes.find((x) => x.value === rowData.dealType)?.label ?? null,
    },
    {
      id: 'financeAccounts',
      label: 'Finance Account',
      render: (rowData) => rowData.financeAccounts.join(', '),
    },
    {
      id: 'claimFrequency',
      label: 'Claim Frequency',
      minWidth: 120,
      render: (rowData) =>
        getClaimFrequency(
          Consts.AgreementTypeEnum.Deal,
          rowData.claimInterval,
          rowData.claimDaysAfterExpiry
        ),
    },
    {
      id: 'excludeReturns',
      label: 'Returns',
      minWidth: 80,
      render: (rowData) => (rowData.excludeReturns ? 'Excluded' : 'Included'),
    },
    {
      id: 'claimAmount',
      label: 'Claim Amount',
      render: (rowData) => (
        <Typography>
          {getDisplayAmountValue(
            rowData.claimAmount,
            '$',
            getGSTType(rowData.claimAmount, rowData.claimTaxAmount),
            2
          )}
        </Typography>
      ),
    },
    {
      id: 'startAt',
      label: 'Start Date',
      minWidth: 80,
      render: (rowData: LinkedDealListItem) => formatDate(rowData.startAt),
    },
    {
      id: 'endAt',
      label: 'End Date',
      minWidth: 80,
      render: (rowData) => formatDate(rowData.endAt),
    },
    {
      id: 'supplierApprovalNumber',
      label: 'Supplier Approval No.',
      minWidth: 120,
      render: (rowData) => <Typography>{rowData.supplierApprovalNumber}</Typography>,
    },
    {
      id: 'comment',
      label: 'Comments',
      minWidth: 120,
      render: (rowData) => <Typography>{rowData.comment}</Typography>,
    },
  ];

  return <SimpleDataTable columns={columns} rows={rows} darkTheme highlightRow={id} />;
};

export default DealLinkedDealsTabContent;
