import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import {Status} from '../Status';

const PREFIX = 'SummaryDetailsComponent';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  reversal: `${PREFIX}-reversal`,
  reversalMessageCtn: `${PREFIX}-reversalMessageCtn`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    flex: 1,
  },

  [`& .${classes.title}`]: {
    fontSize: '1.875rem',
    padding: '2.25rem 0 1.25rem',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },

  [`& .${classes.reversal}`]: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
  },

  [`& .${classes.reversalMessageCtn}`]: {
    backgroundColor: theme.palette.gray.light,
    padding: '0.25rem 1.5rem',
    borderRadius: '0.8125rem',
    textAlign: 'center',
    border: '1px solid black',
  },
}));

const SummaryDesc = styled('div')`
  display: flex;
  align-items: center;
  color: #626262;

  span:first-of-type {
    padding-right: 2.5rem;
  }
`;

const CheckWrapper = styled('div')`
  flex-basis: 100% !important;
`;

const SummaryDetailsContainer = styled('div')`
  width: 100%;
  padding: 3.75rem 0 0;
`;

const SummaryDetails = styled('div')<{itemCountPerRow: number}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  > div {
    flex-basis: ${(props) => `calc(${Math.floor(100 / props.itemCountPerRow)}%)`};
    display: flex;
    flex-direction: column;
    padding: 0 1rem 3.75rem 0;
    box-sizing: border-box;

    > span {
      font-size: 1.125rem;
      display: flex;

      align-items: center;
      grid-gap: 0.375rem;
    }
    span:first-of-type {
      color: #626262;
      font-size: 1rem;
      padding-bottom: 0.625rem;
    }
  }
`;

const SingleInvoiceCheckbox = styled(Checkbox)(({theme}) => ({
  '&.MuiCheckbox-root': {
    padding: '0',
  },
  svg: {
    fill: theme.palette.green.main,
  },
}));

type Props = {
  id: number;
  desc?: string;
  details: {title: string; value?: string}[];
  externalId?: string;
  generatePricebookUpdate?: boolean;
  groupClaimsOnSingleInvoice?: boolean;
  hideOwner?: boolean;
  isPromo?: boolean;
  isReversed?: boolean;
  isReversal?: boolean;
  itemCountPerRow?: number;
  staffCode: number;
  staffName: string;
  status: string;
  type: string;
};

const SummaryDetailsComponent: FC<Props> = ({
  id,
  details,
  status,
  desc,
  staffCode,
  staffName,
  type,
  externalId,
  hideOwner,
  itemCountPerRow = 3,
  generatePricebookUpdate,
  groupClaimsOnSingleInvoice,
  isPromo,
  isReversed,
  isReversal,
}) => (
  <Root className={classes.root}>
    <Stack direction="row" spacing={2} flexWrap="wrap">
      <Status status={status} />
      {isReversal ? (
        <div className={classes.reversalMessageCtn}>
          <Typography className={classes.reversal} variant="h5">
            Reversal
          </Typography>
        </div>
      ) : null}
      {isReversed ? (
        <div className={classes.reversalMessageCtn}>
          <Typography className={classes.reversal} variant="h5">
            Deal Reversed Successfully
          </Typography>
        </div>
      ) : null}
    </Stack>
    <Typography className={classes.title} variant="h4">
      {desc}
    </Typography>
    <SummaryDesc>
      <span>
        {type} ID: {id}
      </span>
      {externalId ? <span>External ID: {externalId}</span> : null}
      {!hideOwner ? (
        <span>
          Owner: {staffCode} - {staffName}
        </span>
      ) : null}
    </SummaryDesc>
    <SummaryDetailsContainer>
      <SummaryDetails itemCountPerRow={itemCountPerRow}>
        {details.length
          ? details.map((detail, index) => (
              <div key={index}>
                <span>{detail.title}</span>
                <span>{detail.value}</span>
              </div>
            ))
          : null}
        {type === 'Agreement' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row !important',
              maxWidth: '14rem',
              alignItems: 'flex-start',
              gridGap: '0.25rem',
            }}
          >
            <SingleInvoiceCheckbox
              disabled
              data-testid="single-invoice-check"
              checked={groupClaimsOnSingleInvoice ?? false}
            />
            <span>All claims to appear on a single invoice</span>
          </Box>
        ) : null}
        {type === 'Deal' && isPromo ? (
          <CheckWrapper>
            <span>Generate Price Book Update</span>
            {generatePricebookUpdate ? (
              <span>
                <Checkbox disabled checked data-testid="price-book-update-check" />
                Selected
              </span>
            ) : null}
          </CheckWrapper>
        ) : null}
      </SummaryDetails>
    </SummaryDetailsContainer>
  </Root>
);

export default SummaryDetailsComponent;
