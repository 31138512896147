import React, {useContext, useEffect, useState, useCallback} from 'react';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import {useFormikContext} from 'formik';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {api, del, get} from '../../utils/Request';
import {isNullish} from '../../utils';
import {AttachmentType, DealReversalFormValues, DealFormBag} from '../../types';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import OptionalIndicator from '../Form/OptionalIndicator';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FilesUploader from '../DragDrop/FilesUploader';
import Attachment from '../Attachment/Attachment';

type Props = {
  step: number;
  title: string;
  totalStep: number;
  onNext: (values: DealReversalFormValues, bag: DealFormBag) => void;
  style?: React.CSSProperties;
};

const StepDealReversalAttachments = ({step, title, totalStep, onNext}: Props) => {
  const {id: originalDealId} = useParams();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  const {values} = useFormikContext<DealReversalFormValues>();
  const {reversalDealId} = values;

  const loadAttachments = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<AttachmentType[]> = await get(
        api(Consts.Api.DealAttachments).replace(':dealId', reversalDealId)
      );
      setAttachments(response.data ?? []);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load attachments', response: error.response},
      });
    } finally {
      hideLoading();
    }
  }, [reversalDealId, hideLoading, showLoading]);

  useEffect(() => {
    if (reversalDealId) {
      loadAttachments();
    }
  }, [loadAttachments, reversalDealId]);

  const uploadComplete = (response: AxiosResponse<AttachmentType[]>) => {
    setAttachments((prevAttachments) => [...prevAttachments, ...(response.data ?? [])]);
  };

  const deleteAttachment = async (attachmentId: AttachmentType['id']) => {
    try {
      showLoading();
      const toDelete = attachments.find((x) => x.id === attachmentId);
      const matchedOriginal = attachments.find(
        (x) => x.fileName === toDelete?.fileName && x.id !== attachmentId
      );

      await del(
        api(Consts.Api.DealAttachment)
          .replace(':dealId', reversalDealId)
          .replace(':id', attachmentId)
      );
      if (matchedOriginal) {
        await del(
          api(Consts.Api.DealAttachment)
            .replace(':dealId', originalDealId)
            .replace(':id', matchedOriginal.id)
        );
      }
      setAttachments((prevAttachments) =>
        prevAttachments.filter((x) => x.id !== attachmentId && x.id !== matchedOriginal?.id)
      );
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleUploadError = (error: any) => {
    alertService.alert({
      id: defaultAlertId,
      ...{message: error.message, response: error.response},
    });
  };

  return (
    <>
      <Stack direction="row" spacing={1} sx={{paddingBottom: '2rem'}}>
        <Box sx={{fontWeight: 'bold'}}>Reversed Deal ID:</Box>
        <Box>{reversalDealId || '-'}</Box>
      </Stack>
      <FormStep step={step} title={title}>
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>
            Add Attachments <OptionalIndicator />
          </FieldLabel>
          <Box>
            <Typography variant="body1" gutterBottom>
              Do you wish to upload supplier rejection notice? (Optional)
            </Typography>
            <Typography variant="caption">
              This will be added to the Original Deal and Reversal Deal
            </Typography>
          </Box>
          {!isNullish(attachments) ? (
            <FilesUploader
              maxSizeInMb={20}
              multiple
              apiEndpoint={[
                api(Consts.Api.DealAttachments).replace(':dealId', reversalDealId),
                api(Consts.Api.DealAttachments).replace(':dealId', originalDealId),
              ]}
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'image/jpg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
                'message/rfc822': ['.eml'], // for eml
                'text/csv': ['.csv'], // for csv
                'application/vnd.ms-outlook': ['.msg'], // for msg
              }}
              title="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
              onComplete={uploadComplete}
              noFiles={attachments.length === 0}
              buttonLabel="Upload Attachments"
              onError={handleUploadError}
            >
              <div>
                {attachments
                  ?.filter((x) => `${x.parentId}` === `${reversalDealId}`)
                  ?.map?.((attachment, index) => (
                    <Attachment key={index} fileName={attachment.fileName}>
                      <IconButton
                        data-testid="delete-attachment"
                        onClick={() => {
                          deleteAttachment(attachment.id);
                        }}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Attachment>
                  ))}
              </div>
            </FilesUploader>
          ) : null}
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection handleNext={onNext} step={step} totalStep={totalStep} />
    </>
  );
};

export default StepDealReversalAttachments;
