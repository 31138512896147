import React from 'react';
import Consts from '../../../app/Consts';
import {useAppSelector} from '../../../app/store';
import {selectConfigsData} from '../../../app/selectors';
import DealForm from '../../../components/DealForm/DealForm';

const CreateDeal = () => {
  // configs are added to form values so they can be accessed as this.parent in the validation schema
  const configs = useAppSelector(selectConfigsData);
  if (!configs) {
    //when the configs are not loaded yet
    return null;
  }
  const initialValues = {
    claimDaysAfterExpiry: undefined,
    claimInterval: '',
    claimVendor: {
      code: '',
      name: '',
      isActive: false,
    },
    claimVendorSuppliers: [],
    comment: '',
    dealType: null,
    dealValueCount: 0,
    dealValues: [],
    departmentNumber: null,
    departments: [],
    description: '',
    draftMode: true,
    endAt: null,
    endTime: null,
    excludeReturns: true,
    externalPromotionIds: '',
    financeAccountCleared: false,
    generatePricebookUpdate: true,
    hasActiveClaim: false,
    hasRaisedClaim: false,
    isClaimVendorGstFree: false,
    locationCriteria: null,
    ownedByStaffCode: null,
    merchandisingPurchaseOrderNumber: '',
    promotionId: null,
    splitAccountAmountType: Consts.SplitAccountAmountTypeEnum.Percentage,
    splitAccounts: [],
    startAt: null,
    startTime: null,
    status: Consts.AgreementStatusEnum.Draft,
    supplierApprovalNumber: '',
    suppliers: [],
    totalAmount: 0,
    DealCutoffDate: configs
      ? configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
      : null,
    DealLockDate: configs
      ? configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
      : null,
  };
  return <DealForm title="Add A New Deal" initialValues={initialValues} />;
};

export default CreateDeal;
