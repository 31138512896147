import React, {FC} from 'react';
import Box from '@mui/material/Box';

type Props = {
  progress: number;
  total: number;
};

const LinearProgress: FC<Props> = ({progress, total}) => (
  <Box
    sx={{
      width: '100%',
      height: '0.5rem',
      borderRadius: '0.5rem',
      backgroundColor: 'gray.lightMid',
    }}
  >
    <Box
      sx={{
        width: `${(progress / total) * 100}%`,
        height: '100%',
        backgroundColor: 'primary.main',
        borderRadius: '0.5rem',
      }}
    />
  </Box>
);

export default LinearProgress;
