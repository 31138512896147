import React, {FC, useContext, useState, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import StepDealDetails from './StepDealDetails';
import StepperForm from '../Form/StepperForm';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {api, put} from '../../utils/Request';
import {DealFormValues} from '../../types';
import StepDealValues from './StepDealValues';
import StepFinanceDetails from './StepFinanceDetails';
import stepDealDetailsValidationSchema from './StepDealDetailsValidationSchema';
import stepFinanceDetailsValidationSchema from './StepFinanceDetailsValidationSchema';
import stepDealValuesValidationSchema from './StepDealValuesValidationSchema';
import StepTagsAndAttachments from './StepTagsAndAttachments';
import stepTagsAndAttachmentsValidationSchema from './StepTagsAndAttachmentsValidationSchema';

function getSteps() {
  return ['Deal Details', 'Add Deal Values', 'Finance Details', 'Attachments'];
}
const breakPoint = 1840;
const StyledStepperForm = styled(StepperForm)(({theme}) => ({
  '&.PageContentContainer-root': {
    [theme.breakpoints.up(breakPoint)]: {
      width: '1500px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    minWidth: '1280px',
    width: 'auto',
    display: 'inline-block',
    paddingLeft: '70px',
    paddingRight: '70px',
    marginLeft: '35px',
    marginRight: '35px',
  },
}));

type Props = {
  title: string;
  initialValues: DealFormValues;
  indexOverride?: number;
};

const DealForm: FC<Props> = ({title, initialValues, indexOverride}) => {
  const navigate = useNavigate();
  const [draftCreationFinished, setDraftCreationFinished] = useState(false);
  const [dealId, setDealId] = useState<string>();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  useEffect(() => {
    // true once, after deal finalisation
    if (dealId && draftCreationFinished) {
      navigate(Consts.RouterPath.DealSummary.replace(':id', dealId));
    }
  }, [dealId, draftCreationFinished, navigate]);

  // validationSchema props are passed to Formik via getStepContent props and StepperForm
  // not via Step form components
  function getStepContent(
    index: number,
    handleBack: (values: DealFormValues) => void,
    handleNext: (values: DealFormValues) => void
  ) {
    const contentIndex = indexOverride ?? index;
    switch (index) {
      case 0:
        return (
          <StepDealDetails
            step={contentIndex + 1}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            validationSchema={stepDealDetailsValidationSchema}
          />
        );
      case 1:
        return (
          <StepDealValues
            step={contentIndex + 1}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            validationSchema={stepDealValuesValidationSchema}
            style={{minWidth: '80rem', width: '94rem'}}
          />
        );
      case 2:
        return (
          <StepFinanceDetails
            step={contentIndex + 1}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            validationSchema={stepFinanceDetailsValidationSchema}
          />
        );
      case 3:
        return (
          <StepTagsAndAttachments
            step={contentIndex + 1}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            validationSchema={stepTagsAndAttachmentsValidationSchema}
          />
        );
      default:
        return null;
    }
  }

  async function finishDealCreation(id: string, generatePricebookUpdate: boolean) {
    const request = {
      generatePricebookUpdate,
    };
    showLoading();
    put(api(Consts.Api.FinishDealAgreementCreation.replace(':id', id)), request)
      .then(() => {
        setDraftCreationFinished(true);
        setDealId(id);
        hideLoading();
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }

  const handleShowRouteChangeMessage = useCallback(
    (values: DealFormValues): boolean =>
      !draftCreationFinished && !!values.id && values.status === Consts.AgreementStatusEnum.Draft,
    [draftCreationFinished]
  );

  return (
    <StyledStepperForm
      title={title}
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (!values.draftMode) {
          await finishDealCreation(values.id, values.generatePricebookUpdate);
        } else {
          navigate(Consts.RouterPath.DealSummary.replace(':id', values.id));
        }
      }}
      steps={getSteps()}
      getStepContent={getStepContent}
      routeChangeMessage="Your deal has been saved to My Drafts"
      showRouteChangeMessage={handleShowRouteChangeMessage}
      indexOverride={indexOverride}
    />
  );
};

export default DealForm;
