import React, {useState, useRef} from 'react';
import {connectCurrentRefinements} from 'react-instantsearch-dom';
import * as R from 'ramda';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tag from './../../Button/Tag';
import {Button} from './../../Button';
import LinearProgress from './LinearProgress';

const TagWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
const RefinementTitle = styled('div')`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const CurrentRefinements = ({
  items,
  refine,
  renderLabel = (label, attribute) => label,
  createURL,
  inclusive = true,
  criteria,
  onDelete = (attribute, value) => {},
  readOnly = false,
  ...otherProps
}) => {
  const loadMoreLimit = 12;
  const [displayLimit, setDisplayLimit] = useState(loadMoreLimit);
  const loadMoreButtonRef = useRef(null);
  const uniqItems = R.uniqWith(R.eqProps('attribute'))(items);

  const handleLoadMore = () => {
    setDisplayLimit((prev) => prev + loadMoreLimit);
    setTimeout(() => {
      loadMoreButtonRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 0);
  };

  function itemKey(label) {
    return `${label} -  ${inclusive ? 'inclusive' : 'exclusive'}`;
  }
  function displayLabel(label, attribute) {
    let newLabel = inclusive ? label : label.slice(1);
    return renderLabel(newLabel, attribute);
  }
  function refinementTitle(item) {
    if (criteria) {
      const option = criteria.find((option) => option.value === item.attribute);
      return option.pluralLabel;
    }
    return item.label;
  }

  function renderList(item) {
    const filteredItems = item.items.filter((nested) =>
      inclusive ? nested.label[0] !== '-' : nested.label[0] === '-'
    );

    if (filteredItems && filteredItems.length > 0) {
      const displayedItems = filteredItems.slice(0, displayLimit);
      const hasMore = filteredItems.length > displayLimit;

      return (
        <div>
          <RefinementTitle>{refinementTitle(item)}</RefinementTitle>
          <TagWrapper>
            {displayedItems
              .filter((nested) => (inclusive ? nested.label[0] !== '-' : nested.label[0] === '-'))
              .map((nested) => (
                <Tag
                  key={itemKey(nested.label)}
                  label={displayLabel(nested.label, item.attribute)}
                  onClose={(event) => {
                    event.preventDefault();
                    refine(nested.value);
                    onDelete(item.attribute, nested.label, inclusive);
                  }}
                  readOnly={readOnly}
                />
              ))}
          </TagWrapper>
          {hasMore ? (
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
              sx={{maxWidth: '26rem', pb: 4, margin: '0 auto'}}
            >
              <Typography sx={{fontSize: '0.75rem'}}>
                Showing {displayedItems.length} of {filteredItems.length}
              </Typography>
              <LinearProgress progress={displayedItems.length} total={filteredItems.length} />
              <Button
                variant="contained"
                style={{width: '11rem', height: '3.5rem', fontSize: '1.125rem'}}
                onClick={handleLoadMore}
              >
                Load more
              </Button>
              <div ref={loadMoreButtonRef} />
            </Stack>
          ) : null}
          <Divider sx={{my: 3}} />
        </div>
      );
    }
    return null;
  }

  return (
    <div>
      {uniqItems.map((item) => (
        <div key={itemKey(item.label)}>{renderList(item)}</div>
      ))}
    </div>
  );
};

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

export default CustomCurrentRefinements;
