import React, {FC, useState, useEffect, useContext, useCallback, useRef} from 'react';
import * as R from 'ramda';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import qs from 'qs';
import {styled} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ErrorIcon from '@mui/icons-material/Error';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useAppDispatch} from '../../../app/store';
import {setDealsTemplateModalOpen} from '../../../app/dealsReducer';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import LoadingContext from '../../../app/LoadingContext';
import {alertService, defaultAlertId, AlertType} from '../../../app/AlertService';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {post, api, put, del, get} from '../../../utils/Request';
import {
  AddEditMixAndMatchGroupProductRequest,
  AddEditMixAndMatchGroupProductResponse,
  GroupProductBulkUploadResponse,
  GroupProductsResponse,
  GroupProductSkuOverlap,
  MixAndMatchGroupResponse,
  MixAndMatchProduct,
  Pagination,
  RecursiveUndefined,
  SelectOption,
  TError,
  TableColumn,
  TableErrors,
  ValidMixAndMatchFormValues,
} from '../../../types';
import SkuSearch from '../../Form/Agolia/SkuSearch';
import TablePaginationWithAddButton from '../../Table/Pagination/TablePaginationWithAddButton';
import {
  TableCellInputField,
  TableCellSelect,
  SaveActionButton,
  CancelActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../../Table';
import {WarnIcon} from '../../Icons';
import {ErrorBox} from '../../Alert';
import {BulkUploadConfirmModal} from '../../Modal';
import {SearchInputField} from '../../SearchInputField';
import {BulkUploadIconButton} from '../../Button';
import GroupConfirmModal from '../GroupConfirmModal';
import {mnmDisabled} from '../mixAndMatchUtils';
import MixAndMatchValuesBulkUploadModal from './MixAndMatchValuesBulkUploadModal';
import SKUOverlapTableCellContent from './SKUOverlapTableCellContent';

const PREFIX = 'GroupProductsTable';

const classes = {
  tableCell: `${PREFIX}-tableCell`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
    '& > span:first-of-type': {
      display: 'inline-block',
      marginTop: '1rem',
    },
  },
});

const ValidateRule = {
  Required: 'Required',
  NumberOnly: 'NumberOnly',
  PositiveNumber: 'PositiveNumber',
};

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;
const ActionCellContainer = styled('div')`
  display: flex;
`;
const DataMode = {
  Edit: 'Edit',
  Add: 'Add',
  Display: 'Display',
};

export const isDuplicateSKUError = (errorMsg: string) =>
  /There is an existing product with the product code/.test(errorMsg);

export const formatErrorMsg = (error: any): string => {
  const decimalError = 'Could not convert string to decimal';
  const arithmeticError = 'Arithmetic overflow error converting numeric to data type numeric.';
  const numberInputErrorMessage = 'Please enter a number less than 100,000,000.';

  if (error?.response?.data?.detail) {
    const containsExpectedError =
      error.response.data?.detail.includes(arithmeticError) ||
      error.response.data?.detail.includes(decimalError);

    return containsExpectedError ? numberInputErrorMessage : error.response.data?.detail;
  }
  return error.message;
};

export type LocalProduct = RecursiveUndefined<MixAndMatchProduct> & {
  dataMode?: string;
  skuOverlap?: GroupProductSkuOverlap[] | null;
};
type ProductQueryParams = {
  searchText?: string;
} & Partial<Pagination>;

export const getProductRequestData = (
  rowData: LocalProduct
): AddEditMixAndMatchGroupProductRequest => {
  const {productCode, gstType, claimAmount} = rowData;
  const nextClaimAmount = !claimAmount && claimAmount !== 0 ? null : claimAmount;
  return {
    productCode,
    gstType: gstType ?? Consts.GstTypeEnum.Exclusive,
    claimAmount: nextClaimAmount,
    ...(!nextClaimAmount && nextClaimAmount !== 0
      ? {}
      : {claimAmountType: rowData.claimAmountType}),
  };
};

const newRowData: LocalProduct = {
  dataMode: DataMode.Add,
  id: undefined,
  ticketPrice: undefined,
  gstType: Consts.GstTypeEnum.Exclusive,
  productCode: undefined,
  description: undefined,
  modelNumber: undefined,
  claimAmount: undefined,
  claimAmountType: Consts.AmountTypeEnum.ValuePerUnit,
};

type Props = {
  groupId: MixAndMatchGroupResponse['id'];
};

const defaultPagination: Pagination = {
  ...Consts.DefaultPagination,
  pageSize: 10,
};

type SKUOverlapState = Record<GroupProductSkuOverlap['sku'], GroupProductSkuOverlap[]>;

const GroupProductsTable: FC<Props> = ({groupId}) => {
  const [keepAdding, setKeepAdding] = useState(false);
  const [clearDataWarningModalOpen, setClearDataWarningModalOpen] = useState(false);
  const [data, setData] = useState<LocalProduct[]>([]);
  const [tableErrors, setTableErrors] = useState<TableErrors>({});
  const [duplicateSKUErrorMsg, setDuplicateSKUErrorMsg] = useState<string | null>(null);
  const [valuesSnapshot, setValuesSnapshot] = useState<LocalProduct[]>([]);
  const [bulkUploadConfirmModalOpen, setBulkUploadConfirmModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [clearExistingDuringBulkUpload, setClearExistingDuringBulkUpload] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const [uploadAgain, setUploadAgain] = useState(false);
  const [showMinProductsWarning, setShowMinProductsWarning] = useState(false);
  const [skuOverlaps, setSKUOverlaps] = useState<SKUOverlapState | null>(null);

  const updateFormProductsRef = useRef(false);
  const setInitialData = useRef(false);

  const dispatch = useAppDispatch();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {groups, hasFinalClaim, id: mixAndMatchId},
    setFieldValue,
  } = bag;

  const disabledState = mnmDisabled(bag.values);
  const group = groups?.find((g) => g.id === groupId) as MixAndMatchGroupResponse;
  const groupIndex = groups?.findIndex((g) => g.id === groupId);
  const overlapBackgroundColour = groupIndex === 2 ? 'green.tint' : 'orange.tint';
  const claimOnGroup = group?.claimOnGroup;

  const products = group?.products?.data as LocalProduct[] | undefined;

  // toggle group overlap alert
  const groupHasSkuOverlaps = Object.keys(skuOverlaps ?? {}).some((sku) =>
    data?.some((product) => product.productCode === sku)
  );

  const handleGroupFormUpdate = useCallback(
    (group: MixAndMatchGroupResponse) => {
      const nextGroups = [...(groups ?? [])];
      const groupIndex = nextGroups?.findIndex((g) => g.id === group.id);
      if (groupIndex === -1) {
        return;
      }
      nextGroups.splice(groupIndex, 1, group);
      setFieldValue('groups', nextGroups);
    },
    [groups, setFieldValue]
  );

  useEffect(() => {
    setValuesSnapshot(products ?? []);
  }, [products]);

  useEffect(() => {
    const {data, ...groupPagination} = group?.products ?? {};
    setPagination({...defaultPagination, ...groupPagination});
  }, [setPagination, group?.products]);

  useEffect(() => {
    if (setInitialData.current) {
      return;
    }
    if (products && products?.length === 0 && !hasFinalClaim) {
      setKeepAdding(true);
      setData([newRowData]);
    } else if (keepAdding && !hasFinalClaim) {
      setData([...(products ?? []), newRowData]);
    } else {
      setData(products ?? [newRowData]);
    }
    setInitialData.current = true;
  }, [products, keepAdding, hasFinalClaim]);

  const updateFormProducts = useCallback(
    (productsData: MixAndMatchProduct[]) => {
      handleGroupFormUpdate({
        ...group,
        products: {
          ...group.products,
          data: productsData as MixAndMatchProduct[],
          totalCount: group.products?.totalCount ?? 0,
          totalPages: group.products?.totalPages ?? 0,
          pageSize: group.products?.pageSize ?? defaultPagination.pageSize,
          currentPage: group.products?.currentPage ?? defaultPagination.currentPage,
        },
      });
    },
    [group, handleGroupFormUpdate]
  );

  useEffect(() => {
    if (updateFormProductsRef.current) {
      updateFormProducts(data as MixAndMatchProduct[]);
      updateFormProductsRef.current = false;
    }
  }, [data, updateFormProducts]);

  const setUpdatedData = (rowData: LocalProduct, propName: string, index: number, value: any) => {
    const nextRowData = {...rowData, [propName]: value};
    validateRow(nextRowData, index, propName);
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1, nextRowData);
      return newData;
    });
  };

  const handleAddRow = () => {
    setUpdatedData(newRowData, 'dataMode', data.length, DataMode.Add);
    setKeepAdding(true);
  };

  const onBulkUpload = () => {
    // skip confirmation when we are uploading again after warnings
    if (products && products.length > 0 && !uploadAgain) {
      setBulkUploadConfirmModalOpen(true);
      setBulkUploadModalOpen(false);
    } else {
      setBulkUploadModalOpen(true);
      setUploadAgain(false);
    }
    setShowMinProductsWarning(false);
  };

  const ValidateWithRules = (
    rules: (string | ((value: any, data: LocalProduct) => any))[],
    value: any,
    rowData: LocalProduct
  ) => {
    let errorMessage;
    const isEmptyOrNil = R.isEmpty(value) || R.isNil(value);

    rules.some((rule) => {
      if (rule === ValidateRule.Required && isEmptyOrNil) {
        errorMessage = Consts.ValidationMessage.Required;
        return true;
      } else if (rule === ValidateRule.NumberOnly && !isEmptyOrNil) {
        const hasError = isNaN(value);
        if (hasError) {
          errorMessage = Consts.ValidationMessage.NumberOnly;
          return true;
        }
      } else if (rule === ValidateRule.PositiveNumber && !isEmptyOrNil) {
        const hasError = isNaN(value) || value < 0;
        if (hasError) {
          errorMessage = Consts.ValidationMessage.PositiveValue;
          return true;
        }
      } else if (typeof rule === 'function') {
        const message = rule(value, rowData);
        if (message) {
          errorMessage = message;
          return true;
        }
      }
      return false;
    });
    return errorMessage;
  };

  const getUpdatedErrors = (
    propName: string,
    errorMessage: string | undefined,
    rowIndex: number,
    errors: TableErrors
  ): TableErrors => {
    if (errorMessage) {
      return R.assocPath([rowIndex, propName, 'error'], errorMessage, errors);
    } else {
      return R.dissocPath([rowIndex, propName, 'error'], errors);
    }
  };

  const shouldShowError = (propName: string, rowIndex: number, errors: TableErrors) => {
    return R.pathSatisfies(
      (prop: TError) => !!(prop && prop.error && prop.touched),
      [rowIndex, propName],
      errors
    );
  };

  const validateRow = (rowData: LocalProduct, rowIndex: number, propTouched?: string) => {
    let isValid = true;
    setTableErrors((prevTableErrors) => {
      let newErrors = {...prevTableErrors};
      const claimAmountRules = [
        ValidateRule.NumberOnly,
        ValidateRule.PositiveNumber,
        (value: number, rowData: LocalProduct) => {
          if (rowData.claimAmountType === Consts.AmountTypeEnum.Percentage) {
            if (value > 100) {
              return Consts.ValidationMessage.PercentageValue;
            }
          }
        },
        claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.SKU ? ValidateRule.Required : '',
      ];
      let claimAmountErrorMessage = ValidateWithRules(
        claimAmountRules,
        rowData.claimAmount,
        rowData
      );
      newErrors = getUpdatedErrors('claimAmount', claimAmountErrorMessage, rowIndex, newErrors);

      //check product code
      const productCodeErrorMessage = ValidateWithRules(
        [ValidateRule.Required],
        rowData.productCode,
        rowData
      );
      newErrors = getUpdatedErrors('productCode', productCodeErrorMessage, rowIndex, newErrors);

      if (propTouched) {
        newErrors = R.assocPath([rowIndex, propTouched, 'touched'], true, newErrors);
      } else {
        // update all props to touched if no propTouched provided.
        if (newErrors && rowIndex in newErrors) {
          Object.keys(newErrors[rowIndex]).forEach(
            (key) => (newErrors[rowIndex][key]['touched'] = true)
          );
        }
      }
      if (newErrors && rowIndex in newErrors) {
        const hasError = Object.values(newErrors[rowIndex]).some((x) => x.error);
        if (hasError) {
          isValid = false;
        }
      }
      return newErrors;
    });

    return isValid;
  };

  const getProducts = useCallback(
    async (params?: ProductQueryParams) => {
      try {
        showLoading();
        const response: AxiosResponse<GroupProductsResponse> = await get(
          api(
            Consts.Api.MixAndMatchGroupProducts.replace(':id', `${mixAndMatchId}`).replace(
              ':groupId',
              `${groupId}`
            )
          ),
          {
            params,
            paramsSerializer: (params: ProductQueryParams) =>
              qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'}),
          }
        );
        const {data: responseData, ...pagination} = response.data ?? {};
        setData(responseData);
        setPagination(pagination);
        handleGroupFormUpdate({...group, products: response.data});
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load group products', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading, groupId, group, mixAndMatchId, handleGroupFormUpdate]
  );

  const handleProductSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
    const params = {
      ...(newSearchText ? {searchText: newSearchText} : {}),
      ...pagination,
      currentPage: 1,
    };
    getProducts(params);
  };

  const onPagination = useCallback(
    (next?: Partial<Pagination>) => {
      const params = {
        ...(searchText ? {searchText} : {}),
        currentPage: next?.currentPage ?? pagination.currentPage,
        pageSize: next?.pageSize ?? pagination.pageSize,
      };
      getProducts(params);
    },
    [getProducts, pagination, searchText]
  );

  const onUploadComplete = async (
    _uploadResponse?: GroupProductBulkUploadResponse,
    keepModalOpen?: boolean
  ) => {
    try {
      setBulkUploadModalOpen(Boolean(keepModalOpen));
      showLoading();
      const response: AxiosResponse<GroupProductsResponse> = await get(
        api(
          Consts.Api.MixAndMatchGroupProducts.replace(':id', `${mixAndMatchId}`).replace(
            ':groupId',
            `${groupId}`
          )
        )
      );
      const {data: responseData, ...pagination} = response.data ?? {};
      setPagination(pagination);
      setData(responseData);
      handleGroupFormUpdate({...group, products: response.data});
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load group products', response: error.response},
      });
    } finally {
      hideLoading();
    }
  };

  const handleUpdateProduct = async (rowData: LocalProduct, index: number) => {
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    setDuplicateSKUErrorMsg(null);
    showLoading();
    alertService.clear(defaultAlertId);
    const requestData = getProductRequestData(rowData);
    try {
      const response: AxiosResponse<AddEditMixAndMatchGroupProductResponse> = await put(
        api(Consts.Api.MixAndMatchGroupProduct.replace(':id', `${rowData.id}`)),
        requestData
      );
      const nextOverlapState = !response.data?.skuOverlap
        ? {}
        : response.data?.skuOverlap?.reduce(
            (acc: SKUOverlapState, curr: GroupProductSkuOverlap) => {
              acc[curr.sku] = [...(acc[curr.sku] ?? []), curr];
              return acc;
            },
            {}
          );
      setSKUOverlaps(nextOverlapState);
      const nextRowData = {...response.data, dataMode: DataMode.Display} as LocalProduct;
      setUpdatedData(nextRowData, 'dataMode', index, DataMode.Display);

      setValuesSnapshot((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1, nextRowData);
        return newData;
      });

      setTableErrors((prevErrors) => {
        const {[index]: _, ...newErrors} = {...prevErrors};
        return newErrors;
      });
      updateFormProductsRef.current = true;
    } catch (error: any) {
      const errMsg = formatErrorMsg(error);
      if (isDuplicateSKUError(errMsg)) {
        setDuplicateSKUErrorMsg(errMsg);
      } else {
        alertService.alert({
          message: errMsg,
        });
      }
    } finally {
      hideLoading();
    }
  };

  const onAdd = async (rowData: LocalProduct, index: number) => {
    setDuplicateSKUErrorMsg(null);
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    showLoading();
    setShowMinProductsWarning(false);
    const requestData = getProductRequestData(rowData);
    try {
      const addProductResponse: AxiosResponse<AddEditMixAndMatchGroupProductResponse> = await post(
        api(Consts.Api.MixAndMatchGroupProducts).replace(':groupId', `${groupId}`),
        requestData
      );
      const nextOverlapState = !addProductResponse.data?.skuOverlap
        ? {}
        : addProductResponse.data?.skuOverlap?.reduce(
            (acc: SKUOverlapState, curr: GroupProductSkuOverlap) => {
              acc[curr.sku] = [...(acc[curr.sku] ?? []), curr];
              return acc;
            },
            {}
          );
      setSKUOverlaps(nextOverlapState);
      const goToNextPage = !!products?.length && !(products.length % pagination.pageSize);
      const currentPage = goToNextPage ? pagination.currentPage + 1 : pagination.currentPage;
      onPagination({currentPage});
      alertService.clear(defaultAlertId);
      setTableErrors({});
    } catch (error: any) {
      const errMsg = formatErrorMsg(error);
      if (isDuplicateSKUError(errMsg)) {
        setDuplicateSKUErrorMsg(errMsg);
      } else {
        alertService.alert({
          message: errMsg,
        });
      }
    } finally {
      hideLoading();
    }
  };

  const onConfirmClearData = () => {
    setClearDataWarningModalOpen(false);
  };

  const onEdit = (rowData: LocalProduct, index: number) => {
    setUpdatedData(rowData, 'dataMode', index, DataMode.Edit);
  };

  const savedProducts = data?.filter((x) => x.dataMode !== DataMode.Add);
  const onDelete = async (rowData: LocalProduct, index: number) => {
    if (!data) {
      return;
    } else if (rowData.dataMode === DataMode.Add) {
      setTableErrors((prevErrors) => {
        const newErrors = {...prevErrors};
        const {[index]: _, ...rest} = newErrors;
        return rest;
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
      setKeepAdding(false);
    } else if (savedProducts.length === 1) {
      setShowMinProductsWarning(true);
    } else {
      setDuplicateSKUErrorMsg(null);
      setSKUOverlaps((prevOverlaps) => {
        if (prevOverlaps && rowData.productCode) {
          const {[rowData.productCode]: _, ...rest} = prevOverlaps;
          return rest;
        }
        return prevOverlaps;
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
      showLoading();
      try {
        await del(api(Consts.Api.MixAndMatchGroupProduct.replace(':id', `${rowData.id}`)));
        alertService.clear(defaultAlertId);
        const isLastItemOnPage = `${products?.length}`.at(-1) === '1' && pagination.currentPage > 1;
        const currentPage = isLastItemOnPage ? pagination.currentPage - 1 : pagination.currentPage;
        onPagination({currentPage});
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    }
  };

  const onCancelEdit = (_rowData: LocalProduct, index: number) => {
    const newRowData = {...valuesSnapshot[index], dataMode: DataMode.Display} as LocalProduct;
    setUpdatedData(newRowData, 'dataMode', index, DataMode.Display);
    setTableErrors((prevErrors) => {
      const {[index]: _, ...newErrors} = {...prevErrors};
      return newErrors;
    });
  };

  const renderClaimAmount = (rowData: LocalProduct) => {
    const unit = Consts.ClaimAmountType.find((item) => item.value === rowData.claimAmountType);
    if ((!rowData.claimAmount && rowData.claimAmount !== 0) || !unit) {
      return '-';
    }
    const prefix = rowData.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit ? '$' : '';
    const postfix = rowData.claimAmountType === Consts.AmountTypeEnum.Percentage ? '%' : '';
    return getDisplayAmountValue(rowData.claimAmount, prefix, postfix);
  };

  const showAmountWarning = (rowData: LocalProduct): boolean => {
    return (
      (rowData.claimAmountType === Consts.AmountTypeEnum.Percentage &&
        !!rowData.claimAmount &&
        rowData.claimAmount > config.dealPercentageAmountWarningThreshold) ||
      (rowData.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit &&
        !!rowData.claimAmount &&
        rowData.claimAmount > config.dealDollarAmountWarningThreshold)
    );
  };

  const getAmountWarning = (rowData: LocalProduct) => {
    if (
      rowData.claimAmountType === Consts.AmountTypeEnum.Percentage &&
      rowData.claimAmount &&
      rowData.claimAmount > config.dealPercentageAmountWarningThreshold
    ) {
      return `The value is greater than ${config.dealPercentageAmountWarningThreshold}%`;
    }
    if (
      rowData.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit &&
      rowData.claimAmount &&
      rowData.claimAmount > config.dealDollarAmountWarningThreshold
    ) {
      return `The value is greater than ${getDisplayAmountValue(
        config.dealDollarAmountWarningThreshold,
        '$'
      )}`;
    }
  };

  const renderEditClaimAmount = (rowData: LocalProduct, rowIndex: number) => {
    const defaultType =
      Consts.ClaimAmountType.find((item) => item.value === rowData.claimAmountType) ??
      Consts.ClaimAmountType[0];
    const showError = shouldShowError('claimAmount', rowIndex, tableErrors);
    const errorMessage = showError && R.path([rowIndex, 'claimAmount', 'error'], tableErrors);
    const showWarning = showAmountWarning(rowData);
    const warningMessage = getAmountWarning(rowData);

    const digits = rowData.claimAmountType === Consts.AmountTypeEnum.Percentage ? 0 : 2;
    const defaultValue =
      rowData.claimAmount !== null && rowData.claimAmount !== undefined
        ? `${rowData.claimAmount}`
        : '';

    const regex = digits ? /^(-?[\d,]*(?:\.\d{0,2})?)?$/ : /^(?!\.)(-?[\d,]*)?$/;
    return (
      <CellContainer>
        <TableCellSelect
          data-testid="edit-claim-amount-type"
          sx={{minWidth: '6.25rem'}}
          options={Consts.ClaimAmountType}
          defaultValue={defaultType}
          onChanged={(option: SelectOption) => {
            setUpdatedData(
              {
                ...rowData,
                claimAmount: undefined,
              },
              'claimAmountType',
              rowIndex,
              option.value
            );
          }}
        />
        <TableCellInputField
          fullWidth
          error={showError}
          warning={!showError && showWarning}
          helperText={errorMessage || warningMessage}
          value={defaultValue}
          placeholder="Add value"
          regexValidation={regex}
          digits={digits}
          onChanged={(value) => {
            const nextValue = value === '' ? null : value;
            const nextRowData = {
              ...rowData,
              claimAmountType: !rowData.claimAmountType
                ? defaultType.value
                : rowData.claimAmountType,
            };
            setUpdatedData(nextRowData, 'claimAmount', rowIndex, nextValue);
          }}
        />
      </CellContainer>
    );
  };

  const getColumns = () => {
    const columns: TableColumn<LocalProduct>[] = [
      {
        id: 'productCode',
        field: 'productCode',
        title: renderSkuColumnTitle(),
        render: (rowData) => {
          const text = rowData.productCode ? `${rowData.productCode} ${rowData.description}` : null;
          const hasSkuOverlap =
            rowData.productCode && (skuOverlaps?.[rowData.productCode] ?? [])?.length > 0;
          return (
            <Stack direction="row" alignItems="flex-end" gap={1}>
              {hasSkuOverlap ? <WarnIcon /> : null}
              <span>{text}</span>
            </Stack>
          );
        },
        renderEdit: renderEditSkuColumn,
        editStyle: {minWidth: '12.5rem'},
      },
      {
        id: 'modelNumber',
        field: 'modelNumber',
        title: 'Model Number',
        render: (rowData) => rowData.modelNumber ?? '',
        renderEdit: (rowData) => <span>{rowData.modelNumber ?? ''}</span>,
      },
      {
        id: 'gstType',
        field: 'gstType',
        title: 'GST',
        render: (rowData) => rowData.gstType,
        renderEdit: renderEditGstColumn,
        editStyle: {minWidth: '7.25rem'},
        hide: group?.claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.SKU,
      },
      {
        id: 'ticketPrice',
        field: 'ticketPrice',
        title: 'Ticket/ELQ Price',
        render: (rowData) => <span>{getDisplayAmountValue(rowData.ticketPrice, '$')}</span>,
        renderEdit: (rowData) => <span>{getDisplayAmountValue(rowData.ticketPrice, '$')}</span>,
      },
      {
        id: 'claimAmount',
        field: 'claimAmount',
        title: 'Claim Amount',
        render: renderClaimAmount,
        renderEdit: renderEditClaimAmount,
        hide: group?.claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.SKU,
      },
      {
        id: 'actions',
        field: null,
        title: '',
        render: renderActionColumn,
        style: {width: '6.25rem'},
        isAction: true,
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  const renderEditGstColumn = (rowData: LocalProduct, index: number) => {
    const defaultValue = Consts.GstType.find((item) => item.value === rowData.gstType);
    const options = Consts.GstType;

    const handleGstTypeChange = (option: SelectOption) => {
      setUpdatedData(rowData, 'gstType', index, option.value);
    };

    return (
      <TableCellSelect
        fullWidth
        sx={{minWidth: '7rem'}}
        options={options}
        defaultValue={defaultValue}
        onChanged={handleGstTypeChange}
      />
    );
  };

  const renderActionColumn = (rowData: LocalProduct, index: number) => {
    const leftActionButtonStyle = {marginRight: '0.25rem'};
    if (rowData.dataMode === DataMode.Add) {
      return (
        <ActionCellContainer>
          <SaveActionButton
            style={leftActionButtonStyle}
            onClick={() => onAdd(rowData, index)}
            disabled={disabledState.actions.addProduct}
          />
          <DeleteActionButton
            disabled={data?.length === 1 || disabledState.actions.removeProduct}
            onClick={() => onDelete(rowData, index)}
          />
        </ActionCellContainer>
      );
    }

    if (rowData.dataMode === DataMode.Edit) {
      return (
        <ActionCellContainer>
          <SaveActionButton
            style={leftActionButtonStyle}
            onClick={() => handleUpdateProduct(rowData, index)}
          />
          <CancelActionButton onClick={() => onCancelEdit(rowData, index)} />
        </ActionCellContainer>
      );
    }

    const disabled =
      (rowData.dataMode !== DataMode.Edit &&
        data?.some((item) => item.dataMode === DataMode.Edit)) ||
      disabledState.actions.editProduct;

    return (
      <ActionCellContainer>
        <EditActionButton
          style={leftActionButtonStyle}
          disabled={disabled}
          onClick={() => onEdit(rowData, index)}
        />
        <DeleteActionButton
          disabled={disabled || disabledState.actions.removeProduct}
          onClick={() => onDelete(rowData, index)}
        />
      </ActionCellContainer>
    );
  };
  const confirmBody = (
    <div>
      This action will permanently clear and replace all the products for this group. Would you like
      to continue?
    </div>
  );
  const renderSkuColumnTitle = () => (
    <span>
      SKU*
      <>
        <BulkUploadIconButton
          onClick={onBulkUpload}
          sx={{
            lineHeight: 'normal',
            fontWeight: 'normal',
            paddingLeft: '1rem',
            paddingRight: '0',
          }}
          disabled={hasFinalClaim}
        >
          Bulk Upload SKUs
        </BulkUploadIconButton>
        <GroupConfirmModal
          open={clearDataWarningModalOpen}
          onOk={onConfirmClearData}
          onCancel={() => {
            setClearDataWarningModalOpen(false);
          }}
          body={confirmBody}
        />
      </>
    </span>
  );

  const renderEditSkuColumn = (rowData: LocalProduct, rowIndex: number) => {
    const showError = shouldShowError('productCode', rowIndex, tableErrors);
    return (
      <SkuSearch
        fullWidth
        disabled={disabledState.fields.groups.products.productCode}
        placeholder="Product search"
        defaultValue={
          rowData.productCode
            ? {
                code: rowData.productCode,
                name: rowData.description,
                ticketPrice: rowData.ticketPrice,
                modelNumber: rowData.modelNumber,
              }
            : null
        }
        errorMessage={showError && R.path([rowIndex, 'productCode', 'error'], tableErrors)}
        onChanged={(item?: {
          code: string;
          name: string;
          ticketPrice: number;
          modelNumber: string;
        }) => {
          if (item) {
            const nextRow = {
              ...rowData,
              productCode: item.code,
              description: item.name,
              ticketPrice: item.ticketPrice,
              modelNumber: item.modelNumber,
            };
            setUpdatedData(nextRow, 'productCode', rowIndex, item.code);
          }
        }}
      />
    );
  };

  const onChangePage = (currentPage: number) => {
    onPagination({currentPage});
  };

  const onChangePageSize = (pageSize: number) => {
    onPagination({currentPage: 1, pageSize});
  };

  const renderCell = (
    column: TableColumn<LocalProduct>,
    row: LocalProduct,
    rowIndex: number
  ): null | React.ReactNode | TableColumn<LocalProduct>['field'] => {
    let rowElement = null;
    if ((row.dataMode === DataMode.Add || row.dataMode === DataMode.Edit) && column.renderEdit) {
      rowElement = column.renderEdit(row, rowIndex);
    } else if (column.render) {
      if (column.isAction) {
        rowElement = <ActionCellContainer>{column.render(row, rowIndex)}</ActionCellContainer>;
      } else {
        rowElement = <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
      }
    } else {
      rowElement = (row[column.field as keyof LocalProduct] as string | null) ?? null;
    }
    return rowElement;
  };

  const renderRow = (
    row: LocalProduct,
    rowIndex: number,
    rowSkuOverlaps?: GroupProductSkuOverlap[] | null
  ) => {
    const rowHasSkuOverlaps = Array.isArray(rowSkuOverlaps) && rowSkuOverlaps.length > 0;
    return (
      <React.Fragment key={`row-${rowIndex}`}>
        <TableRow sx={{backgroundColor: rowHasSkuOverlaps ? overlapBackgroundColour : 'inherit'}}>
          {getColumns().map((column, columnIndex) => {
            const displayMode = row.dataMode === DataMode.Display || !row.dataMode;
            const style = {
              ...column.style,
              ...(!displayMode && column.editStyle ? column.editStyle : {}),
              ...(rowHasSkuOverlaps ? {borderBottom: 'none'} : {}),
            };
            return (
              <TableCell
                classes={{root: displayMode ? '' : classes.tableCell}}
                key={`cell-${rowIndex}-${columnIndex}`}
                style={style}
              >
                {renderCell(column, row, rowIndex)}
              </TableCell>
            );
          })}
        </TableRow>
        {rowHasSkuOverlaps ? (
          <TableRow sx={{backgroundColor: overlapBackgroundColour}}>
            <TableCell colSpan={12} sx={{pt: 0}}>
              <SKUOverlapTableCellContent
                skuOverlaps={rowSkuOverlaps as GroupProductSkuOverlap[]}
              />
            </TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div>Add individual SKUs or Bulk Upload using the link</div>
        <SearchInputField
          placeholder="Search for products in the below group"
          width="26rem"
          onSearch={handleProductSearch}
          defaultValue={searchText ?? ''}
        />
      </Stack>
      {showMinProductsWarning ? (
        <ErrorBox
          type={AlertType.Warning}
          sx={{
            border: 'solid 1px #DA6A00',
            padding: '0.5rem 1rem',
          }}
        >
          <div>Each group in the Mix & Match selection must contain at least one product.</div>
        </ErrorBox>
      ) : null}
      {groupHasSkuOverlaps ? (
        <ErrorBox
          type={AlertType.Warning}
          sx={{
            border: 'solid 1px #DA6A00',
            padding: '0.5rem 1rem',
          }}
        >
          <div>There is an overlap of SKUs in the highlighted items below.</div>
        </ErrorBox>
      ) : null}
      <StyledTableContainer data-testid="group-products-table" sx={{overflowY: 'hidden'}}>
        <Table>
          <TableHead>
            <TableRow>
              {getColumns().map((column, index) => (
                <TableCell key={index} style={column.style}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="products">
            {data.map((row, index) =>
              renderRow(
                row,
                index,
                skuOverlaps === null || !row.productCode
                  ? null
                  : skuOverlaps?.[row.productCode] ?? null
              )
            )}
            {duplicateSKUErrorMsg ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <Alert
                    icon={<ErrorIcon height="1.5rem" width="1.5rem" />}
                    severity="error"
                    sx={{padding: '0 1rem', border: 'solid 1px red'}}
                  >
                    {duplicateSKUErrorMsg}
                  </Alert>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        <TablePaginationWithAddButton
          addButtonText="Add another product"
          disabled={
            data?.some((item) => item.dataMode === DataMode.Add) || disabledState.actions.addProduct
          }
          onAdd={handleAddRow}
          pagination={pagination}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
        <MixAndMatchValuesBulkUploadModal
          title="Bulk Upload Mix & Match Values"
          uploadUrl={api(
            Consts.Api.MixAndMatchBulkUpload.replace(':id', `${groupId}`) +
              `?clearExisting=${clearExistingDuringBulkUpload}`
          )}
          open={bulkUploadModalOpen}
          onClose={() => {
            setBulkUploadModalOpen(false);
          }}
          onOpenTemplate={() => {
            setBulkUploadModalOpen(false);
            dispatch(setDealsTemplateModalOpen(true));
          }}
          onComplete={onUploadComplete}
          onReupload={() => {
            setBulkUploadModalOpen(true);
            setClearExistingDuringBulkUpload(true);
            setUploadAgain(true);
          }}
        />
        <BulkUploadConfirmModal
          open={bulkUploadConfirmModalOpen}
          onKeepAdding={() => {
            setClearExistingDuringBulkUpload(false);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onReplace={() => {
            setClearExistingDuringBulkUpload(true);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onCancel={() => {
            setBulkUploadConfirmModalOpen(false);
          }}
          clearedText="all values in this group"
        />
      </StyledTableContainer>
    </Stack>
  );
};

export default GroupProductsTable;
