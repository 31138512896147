import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {alertService, AlertType, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {MixAndMatchSummaryResponse, EntityActionType} from '../../../types';
import {api, get} from '../../../utils/Request';
import LocationSelector from '../../../components/Form/Agolia/LocationSelector';
import {MoneyGraphIcon, ChevronRightIcon, LocationsIcon} from '../../../components/Icons';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import DynamicContainerWithHeader from '../../../components/Container/DynamicContainerWithHeader';
import {SplitTextComponent} from '../../../components/SplitTextComponent';
import {DeleteAgreementorRebateWithClaimForbiddenModal} from '../../../components/Modal';
import {ErrorBox} from '../../../components/Alert';
import MixAndMatchSummaryDetails from './MixAndMatchSummaryDetails';
import MixAndMatchSummaryHeading from './MixAndMatchSummaryHeading';
import MixAndMatchFinanceDetails from './MixAndMatchFinanceDetails';
import FinanceDetailSelect from './FinanceDetailSelect';

export const sortAndTransformFinanceDetails = (financeDetails: {[key: string]: string}) => {
  return Object.entries(financeDetails)
    .map(([key, value]) => ({
      key,
      value,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
};

const MixAndMatchSummary = () => {
  const {id = ''} = useParams<{id: string}>();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [summaryData, setSummaryData] = useState<MixAndMatchSummaryResponse | undefined>();
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [openDeleteWithClaimForbiddenModal, setOpenDeleteWithClaimForbiddenModal] = useState(false);
  const [selectedFinanceDetailsId, setSelectedFinanceDetailsId] = useState<string>();

  const financeDetailsListOptions = sortAndTransformFinanceDetails(
    summaryData?.financeDetailsList ?? {}
  );

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        showLoading();
        const response: AxiosResponse<MixAndMatchSummaryResponse> = await get(
          api(Consts.Api.MixAndMatchSummary.replace(':id', id))
        );
        setSummaryData(response.data);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    };
    fetchSummary();
  }, [id, hideLoading, showLoading]);

  if (!summaryData) {
    return null;
  }
  return (
    <Stack sx={{backgroundColor: '#FFFFFF', minWidth: '49rem'}}>
      <Stack sx={{padding: '3.125rem'}}>
        {summaryData.isDeleted ? (
          <ErrorBox type={AlertType.Info}>
            This Mix & Match is deleted - it will be removed overnight
          </ErrorBox>
        ) : null}
        <MixAndMatchSummaryHeading mixAndMatchData={summaryData} onCancel={setSummaryData} />
        <Stack spacing={2} direction="row">
          <MixAndMatchSummaryDetails summaryData={summaryData} />
          <Stack spacing={12} direction="column" sx={{width: '40%', minWidth: '22rem'}}>
            <SplitIconAmountComponent
              icon={<MoneyGraphIcon />}
              label="Total Amount Accrued"
              amount={summaryData.totalAmountAccrued}
              taxAmount={summaryData.totalTaxAmountAccrued ?? 0}
              gstType={summaryData.gstType}
            />
            <Stack spacing={2}>
              <DynamicContainerWithHeader title="Location">
                <SplitTextComponent
                  leftLabel={
                    summaryData.locationCriteria
                      ? `${summaryData.locationCriteria.resultCount} locations`
                      : 'All Locations'
                  }
                  buttonLabel="View Locations"
                  icon={<LocationsIcon />}
                  buttonIcon={<ChevronRightIcon />}
                  style={{color: '#DA6A00'}}
                  onButtonClick={() => setLocationSelectorOpen(true)}
                />
              </DynamicContainerWithHeader>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Box sx={{padding: '2rem 3.125rem 3rem 3.125rem', maxWidth: '44rem'}}>
        <FinanceDetailSelect
          financeDetails={financeDetailsListOptions}
          selected={selectedFinanceDetailsId}
          onSelect={setSelectedFinanceDetailsId}
        />
      </Box>
      <Divider />
      <MixAndMatchFinanceDetails
        financeDetailsId={selectedFinanceDetailsId ?? financeDetailsListOptions[0].key}
        summaryGstType={summaryData.gstType}
      />
      {locationSelectorOpen ? (
        <LocationSelector
          fullScreen
          locationCriteria={summaryData.locationCriteria}
          open={locationSelectorOpen}
          handleClose={() => setLocationSelectorOpen(false)}
          readOnly
        />
      ) : null}
      <DeleteAgreementorRebateWithClaimForbiddenModal
        entityActionType={EntityActionType.Deal}
        open={openDeleteWithClaimForbiddenModal}
        onOk={() => setOpenDeleteWithClaimForbiddenModal(false)}
      />
    </Stack>
  );
};

export default MixAndMatchSummary;
