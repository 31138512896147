import React, {FC} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select, {SelectChangeEvent} from '@mui/material/Select';

type Props = {
  financeDetails: {key: string; value: string}[];
  selected: string | undefined;
  onSelect: (id: string) => void;
};

const FinanceDetailSelect: FC<Props> = ({financeDetails, selected, onSelect}) => {
  const activeOption = financeDetails.find((option) => option.key === selected);

  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" sx={{fontWeight: 'bold'}}>
        Select claim vendor
      </Typography>
      <FormControl fullWidth>
        <Select
          displayEmpty
          onChange={handleChange}
          value={activeOption?.key ?? financeDetails[0].key ?? ''}
        >
          {financeDetails.map((option) => (
            <MenuItem key={option.key} value={option.key ?? ''}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default FinanceDetailSelect;
