import React, {FC} from 'react';
import {ConfirmModal} from '../Modal';
import Consts from '../../app/Consts';

const styles = {
  okButton: {
    width: '15.9375rem',
  },
  cancelButton: {
    width: '15.9375rem',
  },
  root: {
    width: '32.5rem',
  },
};

type Props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  agreementSubType: string;
};

const mapAgreementSubType = (agreementSubType: string) => {
  switch (agreementSubType) {
    case Consts.AgreementSubTypeEnum.MixAndMatch:
      return 'mix & match';
    case Consts.AgreementSubTypeEnum.Spiv:
      return 'SPIV';
    default:
      return 'deal';
  }
};

const EditDealConfirmModal: FC<Props> = ({open, onOk, onCancel, agreementSubType}) => {
  const displayType = mapAgreementSubType(agreementSubType);
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={`Go to ${displayType} and edit`}
      cancelText="Go back"
      styles={styles}
    >
      <div>
        <div>
          To edit the claim amount for a {displayType} you need to change the original {displayType}
          . (e.g. add a $ amount cap)
        </div>
      </div>
    </ConfirmModal>
  );
};

export default EditDealConfirmModal;
