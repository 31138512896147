import React from 'react';
import {styled} from '@mui/material/styles';
import {setNestedObjectValues, useFormikContext} from 'formik';
import {BackButton, Button, OutlinedButton} from '../Button';

const PREFIX = 'StepperFormActionSection';

const classes = {
  actionSection: `${PREFIX}-actionSection`,
};

const Root = styled('div')(() => ({
  [`&.${classes.actionSection}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1.9375rem',
    '& > button': {
      minWidth: '12.5rem',
    },
  },
}));

const SaveDraftButton = styled(OutlinedButton)`
  height: 4.375rem;
`;

type Props<T> = {
  handleBack?: (values: T, bag: any) => void;
  handleNext: (values: T, bag: any) => void;
  step: number;
  totalStep: number;
  stepButtonText?: string;
  lastStepButtonText?: string;
  lastStepSaveDraftButtonText?: string;
  children?: React.ReactNode;
};

const StepperFormActionSection = <T extends object>({
  handleBack,
  handleNext,
  step,
  totalStep,
  stepButtonText = 'Next',
  lastStepButtonText = 'Finish',
  lastStepSaveDraftButtonText = 'Save Draft',
  children = null,
}: Props<T>) => {
  const bag = useFormikContext<T>();
  const {setFieldValue, values, isSubmitting, validateForm, setTouched} = bag;
  const lastStep = step === totalStep;

  const renderBackButton = () => {
    if (step === 1 || !handleBack) {
      return null;
    }
    return <BackButton onClick={() => handleBack(values, bag)}>Go Back</BackButton>;
  };

  return (
    <Root className={classes.actionSection}>
      {renderBackButton()}
      {'draftMode' in values && lastStep && values.draftMode ? (
        <SaveDraftButton
          disabled={isSubmitting}
          variant="contained"
          type="button"
          onClick={() => {
            validateForm().then(async (errors) => {
              if (Object.keys(errors).length === 0) {
                await handleNext(values, bag);
              } else {
                setTouched(setNestedObjectValues({...bag.initialValues, ...values} as T, true));
              }
            });
          }}
        >
          {lastStepSaveDraftButtonText}
        </SaveDraftButton>
      ) : null}
      <Button
        disabled={isSubmitting}
        variant="contained"
        type="button"
        onClick={() => {
          if ('draftMode' in values && lastStep && values.draftMode) {
            setFieldValue('draftMode', false);
          }
          validateForm().then(async (errors) => {
            if (Object.keys(errors).length === 0) {
              await handleNext(values, bag);
            } else {
              setTouched(setNestedObjectValues({...bag.initialValues, ...values} as T, true));
            }
          });
        }}
      >
        {lastStep ? lastStepButtonText : stepButtonText}
      </Button>
      {children}
    </Root>
  );
};

export default StepperFormActionSection;
