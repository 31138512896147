/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import qs from 'qs';
import * as R from 'ramda';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import {RootState, useAppDispatch, useAppSelector} from '../../../app/store';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {selectLoggedInStaffCode, selectProfileBuyerDepartments} from '../../../app/selectors';
import {
  DealSideFilter,
  setDealsFilter,
  setDealsOrder,
  setDealsPagination,
  setDealsSearch,
} from '../../../app/dealsReducer';
import {
  Department,
  ListPageParamOptions,
  Order,
  Pagination,
  TableColumn,
  DealAgreementData,
  DealAgreementListResponse,
} from '../../../types';
import {api, get} from '../../../utils/Request';
import {formatDate} from '../../../utils/DateUtils';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {createOrderFromParams} from '../../../utils/common';
import {Status} from '../../Status';
import {SearchInputField} from '../../SearchInputField';
import {Button} from '../../Button';
import NewDealTypeModal from '../../TopNav/NewDealTypeModal';
import {TableTabPanel} from '../../TableTabPanel';
import ButtonsContainer from '../../Container/ButtonsContainer';
import RequireAuth from '../../Auth/RequireAuth';
import {PageTabs} from '../../PageTabs';
import {SimpleDataTable} from '../../SimpleDataTable';
import FiltersIndicator from '../../FiltersIndicator';
import ListFilterContainer from '../../ListFilterContainer';
import DownloadDealCsvButton from './DownloadDealCsvButton';
import DealListingSideFilter from './DealListingSideFilter';

const PREFIX = 'DealListing';

const classes = {
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const Root = styled('div')(({theme}) => ({
  [`&. ${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.containerRoot}`]: {
    paddingLeft: '51px',
    paddingRight: '43px',
  },

  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '2000px',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '50px 0',
  },
}));

const DescriptionColumn = styled('div')`
  word-break: break-word;
`;

const columns: TableColumn<DealAgreementData>[] = [
  {
    id: 'id',
    label: 'Deal ID',
    style: {minWidth: 72},
    render: ({id, mixAndMatchId}: DealAgreementData) => `${mixAndMatchId ?? id}`,
  },
  {
    id: 'dealType',
    label: 'Deal Type',
    sortable: true,
    render: (rowData: DealAgreementData) =>
      Consts.DealTypes.find((x) => x.value === rowData.dealType)?.label ?? null,
  },
  {
    id: 'description',
    label: 'Description',
    style: {minWidth: 150},
    sortable: true,
    render: (rowData: DealAgreementData) => (
      <DescriptionColumn>{rowData.description}</DescriptionColumn>
    ),
  },
  {id: 'claimVendorName', label: 'Claim Vendor', minWidth: 80, sortable: true},
  {
    id: 'financeAcc',
    label: 'Finance Acc.',
    render: (rowData: DealAgreementData) => rowData.financeAccounts.join(', '),
  },
  {
    id: 'startAt',
    label: 'Start Date',
    sortable: true,
    render: (rowData: DealAgreementData) => formatDate(rowData.startAt),
  },
  {
    id: 'endAt',
    label: 'End Date',
    sortable: true,
    render: (rowData: DealAgreementData) => formatDate(rowData.endAt),
  },
  {id: 'ownedByStaffName', label: 'Owner', minWidth: 80, sortable: true},
  {
    id: 'departmentDescription',
    label: 'Department',
    sortable: true,
  },
  {
    id: 'amount',
    label: 'Amount (ex)',
    sortable: true,
    render: (rowData: DealAgreementData) => getDisplayAmountValue(rowData.amount, '$'),
  },
  {
    id: 'status',
    label: 'Status',
    render: (rowData: DealAgreementData) => <Status status={rowData.status} />,
    sortable: true,
  },
  {
    additionalLine: 1,
    id: 'pricebookUpdate',
    isShown: (rowData: DealAgreementData) => Boolean(rowData.generatePricebookUpdate),
    render: (rowData: DealAgreementData) =>
      rowData.generatePricebookUpdate ? (
        <FormControlLabel
          sx={{
            span: {
              color: 'rgb(4, 4, 4) !important',
            },
          }}
          control={<Checkbox checked disabled />}
          label="Pricebook updates"
        />
      ) : null,
    colspan: 1,
    colspanLeft: 1,
    style: {
      fontStyle: 'italic',
      opacity: 0.7,
      paddingTop: 0,
    },
  },
  {
    additionalLine: 1,
    id: 'promotionId',
    isShown: (rowData: DealAgreementData) =>
      !!rowData.supplierApprovalNumber || !!rowData.comment || !!rowData.promotionId,
    render: (rowData: DealAgreementData) =>
      rowData.promotionId ? `Promo ID: ${rowData.promotionId}` : null,
    colspan: 2,
    style: {
      verticalAlign: 'top',
      fontStyle: 'italic',
      opacity: 0.7,
    },
  },
  {
    additionalLine: 1,
    id: 'supplierApprovalNumber',
    isShown: (rowData: DealAgreementData) =>
      !!rowData.supplierApprovalNumber || !!rowData.comment || !!rowData.promotionId,
    render: (rowData: DealAgreementData) =>
      rowData.supplierApprovalNumber
        ? `Supplier Approval Number: ${rowData.supplierApprovalNumber}`
        : null,
    colspan: 3,
    style: {
      verticalAlign: 'top',
      fontStyle: 'italic',
      opacity: 0.7,
    },
  },
  {
    additionalLine: 1,
    id: 'comment',
    isShown: (rowData: DealAgreementData) =>
      !!rowData.supplierApprovalNumber || !!rowData.comment || !!rowData.promotionId,
    render: (rowData: DealAgreementData) =>
      rowData.comment ? `Comments: ${rowData.comment}` : null,
    colspan: 4,
    style: {
      verticalAlign: 'top',
      fontStyle: 'italic',
      opacity: 0.7,
    },
  },
];

export const getDealTypeNavidationPath = (id: number, deals: DealAgreementData[]) => {
  const matchingDeal = deals.find((deal) => deal.id === id);
  if (!matchingDeal) {
    return '';
  }
  switch (matchingDeal.dealType) {
    case Consts.DealTypeEnum.Spiv:
      return Consts.RouterPath.SPIVSummary.replace(':id', id.toString());
    case Consts.DealTypeEnum.MixAndMatch:
      if (matchingDeal.mixAndMatchId) {
        return Consts.RouterPath.MixAndMatchSummary.replace(
          ':id',
          matchingDeal.mixAndMatchId.toString()
        );
      }
      break;
    default:
      return Consts.RouterPath.DealSummary.replace(':id', id.toString());
  }
};

type GetDealsOptions = ListPageParamOptions<DealSideFilter>;

const DealListing = ({my = false}) => {
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const buyerDepartments = useAppSelector(selectProfileBuyerDepartments);
  const {filterSelection, pagination, searchText, order} = useAppSelector(
    (state: RootState) => state.deals
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<DealAgreementData[]>([]);
  const [openSideFilters, setOpenSideFilters] = useState(false);
  const [dealTypeModalOpen, setDealTypeModalOpen] = useState(false);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);

  const filterRef = useRef<HTMLDivElement>(null);

  const setFilterSelection = (newFilters: DealSideFilter) => {
    dispatch(setDealsFilter(newFilters));
  };

  const setPagination = (pagination: Pagination) => {
    dispatch(setDealsPagination(pagination));
  };

  const setSearchText = (searchText: string | null) => {
    dispatch(setDealsSearch(searchText));
  };

  const setOrder = (newOrder: Order) => {
    dispatch(setDealsOrder(newOrder));
  };

  const setData = useCallback((responseData: DealAgreementListResponse) => {
    const deals = responseData.data;
    setRows(deals);
    setLoading(false);
    setPagination({
      totalPages: responseData.totalPages,
      currentPage: responseData.currentPage,
      pageSize: responseData.pageSize,
      totalCount: responseData.totalCount,
    });
  }, []);

  const getDeals = useCallback(
    (params: GetDealsOptions, singleOrderBy?: boolean) => {
      setLoading(true);
      get(api(Consts.Api.DealAgreements), {
        params: {
          entityCode: config.entityCode,
          ownedByStaffCode: my ? loggedInStaffCode : null,
          ...params,
        },
        paramsSerializer: (params: GetDealsOptions) => {
          const {orderBy, ...rest} = params;
          const nextParams =
            singleOrderBy || !isDefaultOrder
              ? params
              : {...rest, orderBy: [orderBy, 'description:asc']};
          const result = qs.stringify(nextParams, {skipNulls: true, arrayFormat: 'repeat'});
          return result;
        },
      })
        .then((response: AxiosResponse<DealAgreementListResponse>) => {
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    },
    [my, setData, loggedInStaffCode, isDefaultOrder]
  );

  const mapGetDealsParams = (options: GetDealsOptions) => {
    let result: any = {};
    const claimVendorCode = options?.claimVendor?.code ?? undefined;
    const financeAccountId = options?.financeAccount?.id ?? undefined;
    const startAt = options?.startAt ?? undefined;
    const endAt = options?.endAt ?? undefined;
    const ownedByStaffCode = my ? loggedInStaffCode : options?.owner?.code ?? undefined;
    const departmentNumber = options?.departments?.map((x: Department) => x.number) ?? undefined;
    const dealTypes = options?.dealTypes?.map((x) => x.value) ?? undefined;
    const dealStatus = options?.status ?? undefined;

    const searchText = options?.searchText ?? undefined;
    const totalCount = options?.totalCount ?? undefined;
    const totalPages = options?.totalPages ?? undefined;
    const pageSize = options?.pageSize ?? undefined;
    const currentPage = options?.currentPage ?? undefined;
    const orderBy =
      options?.orderBy && options?.orderByDirection
        ? `${options.orderBy}:${options.orderByDirection}`
        : undefined;

    const queryParams = [
      {name: 'claimVendorCode', value: claimVendorCode},
      {name: 'financeAccountId', value: financeAccountId},
      {name: 'startAt', value: startAt},
      {name: 'endAt', value: endAt},
      {name: 'ownedByStaffCode', value: ownedByStaffCode},
      {name: 'departmentNumber', value: departmentNumber},
      {name: 'status', value: dealStatus},
      {name: 'searchText', value: searchText},
      {name: 'totalPages', value: totalPages},
      {name: 'pageSize', value: pageSize},
      {name: 'currentPage', value: currentPage},
      {name: 'totalCount', value: totalCount},
      {name: 'orderBy', value: orderBy},
      {name: 'dealType', value: dealTypes},
    ];

    queryParams.forEach((x) => {
      if (x.value) {
        result[x.name] = x.value;
      }
    });

    return result;
  };

  useEffect(() => {
    let {departments, dealTypes, ...filterSelectionWithoutDepartments} = filterSelection;

    if (buyerDepartments && buyerDepartments.length > 0) {
      setFilterSelection({
        departments: buyerDepartments,
        dealTypes,
        ...filterSelectionWithoutDepartments,
      });
      departments = buyerDepartments;
    }

    if (dealTypes && dealTypes.length > 0) {
      setFilterSelection({dealTypes, departments, ...filterSelectionWithoutDepartments});
    }

    const request = mapGetDealsParams({
      searchText,
      departments,
      dealTypes,
      ...pagination,
      ...filterSelectionWithoutDepartments,
      ...order,
    });
    getDeals(request);
  }, [buyerDepartments]);

  const handleFilterByStatusChange = (_: any, newStatus: string) => {
    setFilterSelection({...filterSelection, status: newStatus});
    setPagination({...pagination, currentPage: 1});

    const request = mapGetDealsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...order,
      status: newStatus,
      currentPage: 1,
    });
    getDeals(request);
  };

  const handleDealSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
    setPagination({...pagination, currentPage: 1});

    const request = mapGetDealsParams({
      searchText: newSearchText,
      ...pagination,
      ...filterSelection,
      ...order,
      currentPage: 1,
    });
    getDeals(request);
  };

  const handlePagination = (newPagination: Partial<Pagination>) => {
    const request = mapGetDealsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...newPagination,
      ...order,
    });

    getDeals(request);
  };

  const handleUpdateFilters = (selection: DealSideFilter) => {
    setFilterSelection(selection);
    const request = mapGetDealsParams({
      searchText,
      ...pagination,
      currentPage: 1,
      ...filterSelection,
      ...order,
      ...selection,
    });
    getDeals(request);
  };

  const handleOrderBy = (newOrderBy: string) => {
    setIsDefaultOrder(false);
    const newOrder = createOrderFromParams(newOrderBy, setOrder);
    const request = mapGetDealsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...(newOrder ? newOrder : {}),
    });
    getDeals(request, true);
  };

  const viewDeal = (id: any, ctrlKeyPressed: boolean) => {
    const routerPath = getDealTypeNavidationPath(id, rows);
    if (!routerPath) {
      return;
    }
    if (ctrlKeyPressed) {
      window.open(routerPath, '_blank');
    } else {
      navigate(routerPath);
    }
  };

  const toggleSideFilters = () => {
    if (!openSideFilters) {
      filterRef?.current?.scrollIntoView?.({block: 'start', behavior: 'smooth'});
    }
    setOpenSideFilters((open) => !open);
  };

  const filterCount = useMemo(() => {
    const {status, departments, dealTypes, ...releventFilters} = filterSelection;
    const departmentFilterCount = departments && departments.length > 0 ? 1 : 0;
    const dealTypeFilterCount = dealTypes && dealTypes.length > 0 ? 1 : 0;
    let result = 0;
    if (my) {
      const {owner, ...filtersWithoutOwners} = releventFilters;
      result = R.filter((x: any) => x, R.values(filtersWithoutOwners)).length;
    } else {
      result = R.filter((x: any) => x, R.values(releventFilters)).length;
    }
    return result + departmentFilterCount + dealTypeFilterCount;
  }, [filterSelection, my]);

  const status = Consts.DealsFilterStatus(my).filter(
    (x) => x.value === filterSelection?.status ?? null
  )[0];
  const statusTitle = status?.label ?? null;
  const statusValue = status?.value ?? null;

  return (
    <Root className={classes.root} ref={filterRef}>
      <PageTabs
        title={statusTitle}
        value={statusValue}
        onChange={handleFilterByStatusChange}
        tabsList={Consts.DealsFilterStatus(my)}
        ariaLabel="Filter deals by status"
      />

      <Grid container wrap="nowrap">
        {openSideFilters ? (
          <Grid item>
            <DealListingSideFilter
              my={my}
              defaultSelection={filterSelection}
              onClose={() => setOpenSideFilters(false)}
              onChange={handleUpdateFilters}
            />
          </Grid>
        ) : null}

        <Grid item sx={{width: '100%'}}>
          <Container
            maxWidth="lg"
            classes={{
              root: classes.containerRoot,
              maxWidthLg: classes.containerWidthLg,
            }}
          >
            <ListFilterContainer>
              <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                <FiltersIndicator count={filterCount} onClick={toggleSideFilters} />
                <SearchInputField
                  placeholder="Search deals"
                  width="26rem"
                  onSearch={handleDealSearch}
                  defaultValue={searchText ?? ''}
                />
              </Stack>
              <ButtonsContainer>
                <DownloadDealCsvButton
                  my={my}
                  requestData={mapGetDealsParams({
                    searchText,
                    ...pagination,
                    ...filterSelection,
                    ...order,
                  })}
                />
                <RequireAuth
                  requiredRoles={{
                    any: [Consts.UserRoleEnum.AddOrUpdateDeals],
                  }}
                  alt={null}
                >
                  <Button
                    style={{height: '3.25rem'}}
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setDealTypeModalOpen(true);
                    }}
                  >
                    Add New Deal
                  </Button>
                </RequireAuth>
              </ButtonsContainer>
            </ListFilterContainer>
            <TableTabPanel loading={loading} className={classes.tabPanelRoot}>
              <SimpleDataTable
                columns={columns}
                rows={rows}
                pagination={pagination}
                onChangePage={handlePagination}
                onChangePageSize={handlePagination}
                onOrderBy={(o) => handleOrderBy(o.orderBy)}
                sortOrderBy={order.orderBy}
                sortOrder={order.orderByDirection}
                onRowClick={viewDeal}
                highlightColumn={order.orderBy}
              />
            </TableTabPanel>
          </Container>
        </Grid>
      </Grid>
      <NewDealTypeModal open={dealTypeModalOpen} onClose={() => setDealTypeModalOpen(false)} />
    </Root>
  );
};

export default DealListing;
