import React, {FC, useCallback, useState} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {connectRefinementList} from 'react-instantsearch-dom';
import {RefinementListProvided, RefinementListExposed} from 'react-instantsearch-core';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import {AlertType} from '../../../app/AlertService';
import searchClient from '../../../utils/algoliaSearchClient';
import {QueryCriteria} from '../../../types';
import {ErrorBox} from '../../Alert';

type BulkSKUQueryHit = {
  sku: string;
  skuTitle: string;
  objectId: string;
  _highlightResult: any;
};

export type Props = {
  onSearchResolve: (products: QueryCriteria) => void;
  inclusive?: boolean;
} & RefinementListProvided &
  RefinementListExposed;

export const BulkSKUSelector: FC<Props> = ({onSearchResolve, inclusive, refine}) => {
  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const searchProducts = useCallback(
    async (searchRequestSkus: string) => {
      try {
        setErrorMessage('');
        const batchSize = 60;
        const searchQueries = [];

        const skuMap = searchRequestSkus.split(',').reduce((acc, sku) => {
          acc.set(sku.trim(), true);
          return acc;
        }, new Map<string, boolean>());
        const skus = Array.from(skuMap.keys());

        for (let i = 0; i < skus.length; i += batchSize)
          searchQueries.push({
            indexName: Consts.AlgoliaIndex.Products,
            filters: `entityCode:"${config.entityCode}"`,
            facets: [Consts.AlgoliaFacet.ProductSkuBulk.Index],
            params: {
              query: skus.slice(i, i + batchSize).join(','),
              hitsPerPage: 3000,
              responseFields: ['hits'],
              attributesToRetrieve: ['sku', 'skuTitle'],
            },
          });

        const results = await searchClient?.search(searchQueries);
        const searchResponseHits = results?.results?.flatMap(
          (result: {hits: BulkSKUQueryHit[]}) => result.hits
        );
        if (searchResponseHits?.length > 0) {
          const refinedValues: string[] = Array.from(
            searchResponseHits
              .reduce((acc: Map<string, string>, hit: BulkSKUQueryHit) => {
                // search results include a lot more than the requested skus
                // by filtering response by the reqested skus, we *assume* we have all the requested skus
                if (hit.sku && skuMap.has(hit.sku)) {
                  const value = inclusive ? hit.skuTitle : `-${hit.skuTitle}`;
                  acc.set(hit.skuTitle, value);
                }
                return acc;
              }, new Map<string, string>())
              .values()
          );
          // validated search results added to the refinement list (tags)
          refine(refinedValues);
          const queryCriteria: QueryCriteria = {
            indexName: Consts.AlgoliaIndex.Products,
            filters: `entityCode:"${config.entityCode}"`,
            resultCount: refinedValues.length,
            facetInclusions: inclusive
              ? [
                  {
                    name: Consts.AlgoliaFacet.ProductSkuBulk.Index,
                    facetType: Consts.AlgoliaFacet.ProductSkuBulk.Type,
                    values: refinedValues,
                  },
                ]
              : [],
            facetExclusions: !inclusive
              ? [
                  {
                    name: Consts.AlgoliaFacet.ProductSkuBulk.Index,
                    facetType: Consts.AlgoliaFacet.ProductSkuBulk.Type,
                    values: refinedValues,
                  },
                ]
              : [],
          };

          onSearchResolve(queryCriteria);
          setSearchValue('');
        }
      } catch (error: any) {
        setErrorMessage('Error searching for SKUs');
      }
    },
    [onSearchResolve, inclusive, refine]
  );

  const handleSearch = (value?: string) => {
    if (!value) return;
    if (value.split(',').length > 3000) {
      setErrorMessage('A maximum 3000 SKUs can be added');
      return;
    }
    searchProducts(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = (event.target as HTMLInputElement).value;
      handleSearch(value);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleSearch(event.target.value);
  };

  return (
    <Box sx={{position: 'relative', width: '100%'}}>
      {errorMessage ? (
        <ErrorBox
          type={AlertType.Warning}
          sx={{
            padding: 0,
            border: 'solid 1px #DA6A00',
            fontSize: '0.875rem',
            position: 'absolute',
            bottom: '100%',
            left: 0,
            right: 0,
            marginBottom: '0.5rem',
            svg: {
              height: '1.25rem !important',
            },
          }}
        >
          {errorMessage}
        </ErrorBox>
      ) : null}
      <TextField
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder="Enter comma separated SKU numbers"
        fullWidth
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </Box>
  );
};

export default connectRefinementList(BulkSKUSelector);
