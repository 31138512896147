import React, {FC, useState} from 'react';
import Consts from '../../../../app/Consts';
import {
  BulkUploadLine,
  GroupProductBulkUploadResponse,
  GroupProductSkuOverlap,
} from '../../../../types';
import {WarnIcon} from '../../../../components/Icons';
import {ConfirmModal} from '../../../../components/Modal';
import ErrorSection, {ErrorList, ErrorListItem} from '../../../../components/Modal/ErrorSection';
import BulkUploadSKUOverlapTable from './BulkUploadSKUOverlapTable';

const styles = {
  okButton: {
    width: '21.25rem',
  },
  cancelButton: {
    width: '21.25rem',
  },
  content: {
    width: '60rem',
  },
  root: {
    width: 'fit-content',
  },
};

type Props = {
  open: boolean;
  data?: GroupProductBulkUploadResponse;
  onClose: () => void;
  onReupload: () => void;
};

const BulkUploadInfoModal: FC<Props> = ({open, data, onClose, onReupload}) => {
  const [currentErrror, setCurrentError] = useState(Consts.ProductBulkUploadErrorType.None);
  const lineWarnings = data?.warning?.lineWarnings ?? [];
  const skuOverlaps = (data?.mixAndMatchGroupProductsResponse
    ?.flatMap((gp) => gp.skuOverlap)
    .filter(Boolean) ?? []) as GroupProductSkuOverlap[];

  if (lineWarnings.length === 0 && skuOverlaps.length === 0) {
    return null;
  }

  const renderSubtitle = () => (
    <div>Please review the below information and choose how to proceed.</div>
  );

  const renderLineWarnings = (lineWarnings: BulkUploadLine[]) => {
    if (lineWarnings.length > 0) {
      const count = lineWarnings.length;
      const expanded = currentErrror === Consts.ProductBulkUploadErrorType.LineWarnings;
      return (
        <ErrorSection
          title={`${count} ${count > 1 ? 'lines have warnings' : 'line has warnings'}`}
          toggleButtonText="View Warnings"
          expanded={expanded}
          onToggle={() => {
            setCurrentError(
              expanded
                ? Consts.ProductBulkUploadErrorType.None
                : Consts.ProductBulkUploadErrorType.LineWarnings
            );
          }}
          sectionIcon={WarnIcon}
        >
          {expanded ? (
            <ErrorList>
              {lineWarnings.map((lineError: BulkUploadLine) => {
                let message = lineError.warningMessages.join(', ');
                return (
                  <ErrorListItem key={lineError.line}>
                    Line {lineError.line} - {message}
                  </ErrorListItem>
                );
              })}
            </ErrorList>
          ) : null}
        </ErrorSection>
      );
    }
    return null;
  };

  const renderSKUOverlap = (overlaps: GroupProductSkuOverlap[]) => {
    if (overlaps.length > 0) {
      const count = overlaps.length;
      const expanded = currentErrror === Consts.ProductBulkUploadErrorType.SKUOverlap;
      return (
        <ErrorSection
          title={`${count} ${count > 1 ? 'lines have SKU overlaps' : 'line has SKU overlaps'}`}
          toggleButtonText="View SKU Overlaps"
          expanded={expanded}
          onToggle={() => {
            setCurrentError(
              expanded
                ? Consts.ProductBulkUploadErrorType.None
                : Consts.ProductBulkUploadErrorType.SKUOverlap
            );
          }}
          sectionIcon={WarnIcon}
        >
          {expanded ? <BulkUploadSKUOverlapTable data={overlaps} /> : null}
        </ErrorSection>
      );
    }
    return null;
  };

  return (
    <ConfirmModal
      title="Upload Information"
      open={open}
      onOk={onClose}
      onCancel={onReupload}
      okText="Continue with current upload"
      cancelText="Upload Again"
      renderSubtitle={renderSubtitle}
      styles={styles}
    >
      {renderLineWarnings(lineWarnings)}
      {renderSKUOverlap(skuOverlaps)}
    </ConfirmModal>
  );
};

export default BulkUploadInfoModal;
